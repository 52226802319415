

import { Vue, Component } from 'vue-property-decorator'
import { ExperienceData, Tags } from '../../types/experienceApply'
import { ElForm } from 'element-ui/types/form'
import { pInteger } from '@/utils/validate'

@Component({
  name: 'experienceEdit'
})
export default class extends Vue {
  private addInfo: ExperienceData = {
    productId: '',
    // 序号
    orderNum: '',
    // 产品类型 1体验 2专业
    type: '',
    // 标签ID
    labelIds: '',
    // 详细地址
    url: '',
    // 产品名称
    productName: '',
    // 产品描述
    productDescribe: ''
  }

  private businessTagList: Tags[] = []
  private labelList = []

  private rules = {
    orderNum: [{ validator: pInteger, trigger: ['blur', 'change'] }]
  }

  get productId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getBusinessTagList()
    if (this.productId) {
      this.getDetail()
    }
  }

  // 获取业务标签列表
  getBusinessTagList () {
    this.$axios.get(this.$apis.experienceApply.selectTenantProductLabelByList).then(res => {
      this.businessTagList = res.list || []
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.experienceApply.selectDetailByProductId, {
      productId: this.productId
    }).then(res => {
      this.addInfo = res
      this.labelList = res.labelIds.split(',')
    })
  }

  submit (formName: string) {
    (this.$refs[formName] as ElForm).validate(valid => {
      if (valid) {
        this.addInfo.labelIds = this.labelList.length > 0 ? this.labelList.join(',') : ''
        if (this.addInfo.labelIds && !this.addInfo.type) {
          this.$message.warning('请选择产品类别')
          return false
        }
        this.$axios.post(this.$apis.experienceApply.updateTenantProduct, this.addInfo).then(() => {
          this.$message.success('添加成功！')
          this.$router.back()
        })
      }
    })
  }
}
